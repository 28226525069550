import React from 'react'
import {Divider, Text, Layout} from '@ui-kitten/components'
import {useTranslation} from "react-i18next"
import {StyleSheet, ImageBackground, ViewProps} from 'react-native'

const DrawerHeader: React.FC<ViewProps> = props => {
  const {t} = useTranslation()

  return (
    <>
      <Layout style={styles.wrapper}>
        <ImageBackground
          style={[props.style, styles.header]}
          source={require('../../../assets/icon-transparent.png')}
        />
        <Text category="h4">{t('drawer.header') as string}</Text>
      </Layout>
      <Divider/>
    </>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    alignItems: 'center',
  },
  header: {
    height: 128,
    width: 128,
    resizeMode: 'contain',
  },
})

export default DrawerHeader
