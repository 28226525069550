import SystemService from "./SystemService"
import Bugsnag, {init} from "./Bugsnag"
import LoggerService from "./Logger/LoggerService"
import ErrorBoundary, {ErrorBoundaryProps} from "../components/Error/ErrorBoundary"
import React from "react"

class ErrorTrackingService {
  private readonly enabled: boolean

  constructor() {
    this.enabled = SystemService.isProduction()
  }

  init(): void {
    if (!this.enabled) {
      return
    }

    init()
  }

  getErrorBoundary(): React.ComponentType<ErrorBoundaryProps> {
    if (!this.enabled) {
      return ErrorBoundary
    }

    return Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? ErrorBoundary
  }

  track(error: Error): void {
    if (!this.enabled) {
      LoggerService.error(error)
    } else {
      Bugsnag.notify(error)
    }
  }
}

export default new ErrorTrackingService()
