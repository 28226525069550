import React from 'react'
import useLazyScreens from "../hooks/useLazyScreens"
import SettingsScreen from "../screens/SettingsScreen"
import {MainDrawerNavigatorContent} from "./MainDrawerNavigatorContent"

const MainDrawerNavigator: React.FC = () => {
  return useLazyScreens([
    "BrowseNamesScreen",
    "RandomNameScreen",
    "LikedScreen",
    "Docs/PrivacyPolicyScreen",
    "Docs/TermsAndConditionsScreen",
    "NotFoundScreen",
    "SettingsScreen",
  ], MainDrawerNavigatorContent)
}

export default MainDrawerNavigator
