import React, {useRef, useEffect, useCallback} from 'react'
import {NavigationContainer, NavigationContainerRef} from '@react-navigation/native'
import MainDrawerNavigator from "./MainDrawerNavigator"
import {NavigationState} from "@react-navigation/routers"
import {PartialState, Route} from "@react-navigation/routers/src/types"
import AnalyticsService from "../services/AnalyticsService"
import * as Linking from "expo-linking"
import routes from "../config/routes"
import Constants from "expo-constants"

const getActiveRouteName: (state: NavigationState | undefined) => string | undefined = state => {
  if (!state) {
    return undefined
  }

  const route: Route<string> & {
    state?: NavigationState | PartialState<NavigationState>
  } = state.routes[state.index]

  if (route.state) {
    // @ts-ignore
    return getActiveRouteName(route.state)
  }

  return route.name
}
const prefix = Linking.makeUrl('/')
const linking = {
  prefixes: [
    ...Constants?.manifest?.extra?.linking?.urls,
    prefix,
  ],
  config: {
    screens: {
      [routes.main.browse]: {
        initialRouteName: routes.browse.names,
        // todo: refactor details into a shared route
        screens: {
          [routes.browse.names]: 'names',
          [routes.details]: {
            path: 'names/:id',
            parse: {
              id: Number,
            },
          },
        },
      },
      [routes.main.random]: 'random',
      [routes.main.liked]: {
        screens: {
          [routes.liked.names]: 'liked',
          [routes.details]: {
            path: 'liked/names/:id',
            parse: {
              id: Number,
            },
          },
        },
      },
      [routes.settings.root]: 'settings',
      [routes.privacyPolicy]: 'policy',
      [routes.termsAndConditions]: 'terms',
      [routes.notFound]: '*',
    },
  },
}

const AppNavigator: React.FC = () => {
  const routeNameRef = useRef<string | undefined>(undefined)
  const navigationRef = useRef<NavigationContainerRef>(null)
  const handleStateChange = useCallback(state => {
    const previousRouteName = routeNameRef.current
    const currentRouteName = getActiveRouteName(state)

    if (previousRouteName !== currentRouteName) {
      AnalyticsService.logCurrentScreen(currentRouteName)
    }

    // Save the current route name for later comparison
    routeNameRef.current = currentRouteName
  }, [routeNameRef])

  useEffect(() => {
    const state = navigationRef.current?.getRootState()

    // Save the initial route name
    routeNameRef.current = getActiveRouteName(state)
  }, [navigationRef.current])

  return (
    <NavigationContainer
      ref={navigationRef}
      onStateChange={handleStateChange}
      linking={linking}
    >
      <MainDrawerNavigator/>
    </NavigationContainer>
  )
}

export default AppNavigator
