import {Share, ShareAction} from "react-native"
import {Name} from "../Entity/Name/Name"
import ErrorTrackingService from "../ErrorTrackingService"
import AnalyticsService from "../AnalyticsService"

class ShareService {
  public async shareName(name: Name): Promise<void> {
    const result = await this.shareText(`Цікаве ім'я. Що думаєте? https://names.kripiz.com/names/${name.id}`)

    if (result === null) {
      return
    }

    if (result?.action === Share.sharedAction) {
      AnalyticsService.logNameShare()
    } else if (result?.action === Share.dismissedAction) {
      AnalyticsService.logNameShareDismiss()
    }
  }

  public async shareText(text: string): Promise<ShareAction | null> {
    try {
      return await Share.share({
        message: text,
      })
    } catch (error) {
      ErrorTrackingService.track(error)
    }

    return null
  }
}

export default new ShareService()
