import {combineReducers} from 'redux'
import list from "./list"
import liked from "./liked"
import single from "./single"
import share from "./share"
import image from "./image"
import {persistReducer} from "redux-persist"
import {PersistConfig} from "redux-persist/es/types"
import {storage} from "../../storage"

const persistConfig: PersistConfig<any> = {
  key: 'liked',
  storage: storage,
  whitelist: ['data'],
}

const imagePersistConfig: PersistConfig<any> = {
  key: 'image',
  storage: storage,
  whitelist: ['images'],
}

const likedReducer = persistReducer(persistConfig, liked)
const imageReducer = persistReducer(imagePersistConfig, image)

export default combineReducers({
  list,
  liked: likedReducer,
  single,
  share,
  image: imageReducer,
})
