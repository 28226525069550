import React from 'react'
import {Text, StyleSheet, View} from "react-native"
import ScreenWrapper from "../components/ScreenWrapper"

const BareLoadingScreen: React.FC = () => {
  return (
    <ScreenWrapper>
      <View style={styles.wrapper}>
        <Text>loading...</Text>
      </View>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10%',
  },
})

export default BareLoadingScreen
