import {useDispatch, useSelector} from "react-redux"
import {get} from "lodash"
import {useMemo, useCallback, useState, useEffect} from 'react'
import {showSettingsFeature} from "../redux/modules/settings"
import {Feature} from "../types/settings/feature"
import {RootState} from "../redux/rootReducer"

const useFeature = (name: string) => {
  const features = useSelector<RootState>(state => state.modules.settings.features)
  const dispatch = useDispatch()
  const feature = useMemo((): Feature => get(features, name), [name, features])
  const showFeature = useCallback(() => dispatch(showSettingsFeature(name)), [dispatch, name])
  const [visible, setVisible] = useState(false)
  const hideFeature = useCallback(() => setVisible(false), [setVisible])

  useEffect(() => {
    if (feature?.isShown === true) {
      return
    }

    setVisible(true)

    return () => {
      setVisible(false)
    }
  }, [])

  useEffect(() => {
    if (visible) {
      showFeature()
    }
  }, [visible])

  return [
    visible,
    hideFeature,
    feature,
    showFeature,
  ] as [
    boolean,
    () => void,
    Feature,
    () => void,
  ]
}

export default useFeature
