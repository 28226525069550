import {AppThunk} from "../../types"
import {Name} from "../../../services/Entity/Name/Name"
import {NameParams} from "../../../services/Entity/Name/NameParams"
import {findIndex} from 'lodash'
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {Countries} from "../../../config/Countries"

export interface LikedState {
  data: Array<Name>,
  params: NameParams,
}

const initialState: LikedState = {
  data: [],
  params: {},
}

const likedSlice = createSlice({
  name: 'names/liked',
  initialState,
  reducers: {
    add(state, action: PayloadAction<Name>) {
      if (findIndex(state.data, ['id', action.payload.id]) !== -1) {
        return
      }

      state.data.push(action.payload)
      state.data.sort((a, b) => a.name.localeCompare(b.name, Countries.Ua))
    },
    remove(state, action: PayloadAction<Name>) {
      state.data = state.data.filter(name => name.id !== action.payload.id)
    },
    setParams(state, action: PayloadAction<NameParams>) {
      state.params = action.payload
    }
  }
})

export const {add, remove, setParams} = likedSlice.actions

export default likedSlice.reducer

export function likeName(name: Name): AppThunk {
  return dispatch => {
    dispatch(add(name))
  }
}

export function unlikeName(name: Name): AppThunk {
  return dispatch => {
    dispatch(remove(name))
  }
}

export function setLikedParams(params: NameParams): AppThunk {
  return dispatch => {
    dispatch(setParams(params))
  }
}
