import {AppThunk} from "../../types"
import {Name} from "../../../services/Entity/Name/Name"
import ShareService from "../../../services/Name/ShareService"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"

interface ShareState {}
const initialState: ShareState = {}

const shareSlice = createSlice({
  name: 'names/share',
  initialState,
  reducers: {
    share(state, action: PayloadAction<Name>) {}
  }
})

export const {share} = shareSlice.actions

export default shareSlice.reducer

export function shareName(name: Name): AppThunk {
  return dispatch => {
    dispatch(share(name))

    ShareService.shareName(name)
    //todo: handle error
  }
}
