import {combineReducers, Reducer} from 'redux'
import modules from './modules'

const rootReducer: Reducer = combineReducers({
  modules,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
