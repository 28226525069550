import React, {useCallback} from 'react'
import {useTranslation} from "react-i18next"
import system from "../../../config/system"
import {DrawerItem} from "@ui-kitten/components"
import CloudDownloadIcon from "../../Icons/CloudDownloadIcon"

const DrawerItemDownloadApp: React.FC = () => {
  const {t} = useTranslation()

  const handlePress = useCallback(() => {
    window.open(system.playStoreUrl, '_blank')
  }, [])

  return (
    <DrawerItem
      title={t('drawer.downloadApp') as string}
      onPress={handlePress}
      accessoryLeft={CloudDownloadIcon}
    />
  )
}

export default DrawerItemDownloadApp
