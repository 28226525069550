import {AppThunk} from "../types"
import {Category} from "../../services/Entity/Category/Category"
import {CategoryParams} from "../../services/Entity/Category/CategoryParams"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import LoggerService from "../../services/Logger/LoggerService"
import SheetCategoryService from "../../services/Entity/Category/SheetCategoryService"

export interface CategoriesState {
  data: Array<Category>,
  params: CategoryParams,
  error: boolean,
  success: boolean,
  loading: boolean,
}
const initialState: CategoriesState = {
  data: [],
  params: {},
  error: false,
  success: false,
  loading: false,
}

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    load(state) {
      state.error = false
      state.success = false
      state.loading = true
    },
    success(state, action: PayloadAction<{data: Category[]}>) {
      state.data = action.payload.data
      state.error = false
      state.success = true
      state.loading = false
    },
    error(state, action: PayloadAction<Error>) {
      state.error = true
      state.success = false
      state.loading = false
    },
  }
})

export const {load, success, error} = categoriesSlice.actions

export default categoriesSlice.reducer

export function loadCategories(): AppThunk {
  return dispatch => {
    dispatch(load())

    SheetCategoryService
      .getList()
      .then(data => dispatch(success({data})))
      .catch(e => {
        LoggerService.error(e)
        dispatch(error(e))
      })
  }
}


