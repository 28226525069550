import {useDispatch, useSelector} from "react-redux"
import {useMemo, useEffect} from 'react'
import {setSettingsParam} from "../redux/modules/settings"
import {RootState} from "../redux/rootReducer"
import dark from "../config/themes/dark"
import light from "../config/themes/light"
import {Theme} from "../config/themes/theme"
import {ThemeType} from "@ui-kitten/components/theme/theme/theme.service"
import {useColorScheme} from "react-native"

const useAppTheme = () => {
  const darkMode = useSelector<RootState>(state => state.modules.settings.params.darkMode)
  const colorScheme = useColorScheme()
  const dispatch = useDispatch()

  useEffect(() => {
    if (darkMode !== null) {
      return
    }

    dispatch(setSettingsParam('darkMode', colorScheme === Theme.Dark))
  }, [])

  return [
    darkMode,
    useMemo(() => darkMode ? dark : light, [darkMode])
  ] as [boolean | null, ThemeType]
}

export default useAppTheme
