import SheetNameService from "../../../services/Entity/Name/SheetNameService"
import {AppThunk} from "../../types"
import {Name} from "../../../services/Entity/Name/Name"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import LoggerService from "../../../services/Logger/LoggerService"

export interface SingleState {
  data: Name | null,
  error: Error | null,
  success: boolean,
  loading: boolean,
}
const initialState: SingleState = {
  data: null,
  error: null,
  success: false,
  loading: false,
}

const singleSlice = createSlice({
  name: 'names/single',
  initialState,
  reducers: {
    load(state) {
      state.error = null
      state.success = false
      state.loading = true
    },
    success(state, action: PayloadAction<Name>) {
      state.data = action.payload
      state.error = null
      state.success = true
      state.loading = false
    },
    error(state, action: PayloadAction<Error>) {
      state.error = action.payload
      state.success = false
      state.loading = false
    },
  }
})

export const {load, success, error} = singleSlice.actions

export default singleSlice.reducer

export function getName(id: number): AppThunk {
  return dispatch => {
    dispatch(load())

    SheetNameService
      .getSingle(id)
      .then(data => dispatch(success(data)))
      .catch(e => {
        LoggerService.error(e)
        dispatch(error(e))
      })
  }
}
