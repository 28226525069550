import rootReducer from './rootReducer'
import {Store} from 'redux'
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import {Persistor} from "redux-persist/es/types"
import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit"

const store: Store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }),
})

export type AppDispatch = typeof store.dispatch

const persistor: Persistor = persistStore(store)

export {
  store,
  persistor,
}
