export default {
  main: {
    browse: 'MainBrowse',
    liked: 'MainLiked',
    random: 'MainRandom',
  },
  details: 'NameDetails',
  browse: {
    names: 'BrowseNames',
    tabs: {
      boys: 'BrowseTabBoys',
      girls: 'BrowseTabGirls',
    }
  },
  liked: {
    names: 'LikedNames',
    tabs: {
      boys: 'LikedTabBoys',
      girls: 'LikedTabGirls',
    }
  },
  settings: {
    root: 'SettingsRoot',
  },
  notFound: 'NotFound',
  privacyPolicy: 'HomePrivacyPolicy',
  termsAndConditions: 'HomeTermsAndConditions',
}
