export default {
  appName: 'Дитячі імена',
  screens: {
    browse: {
      title: 'Імена',
    },
    liked: {
      title: 'Вподобані',
    },
    random: {
      title: 'Випадково',
      subtitle: 'Змах вліво - далі, вправо - зберегти',
    },
    settings: {
      title: 'Налаштування',
    },
    privacyPolicy: {
      title: 'Політика конфіденційності',
    },
    termsAndConditions: {
      title: 'Умови використання',
    },
  },
  random: {
    left: 'Вліво',
    right: 'Вправо',
  },
  name: {
    details: {
      description: 'Опис',
      meaning: 'Значення',
      origin: 'Походження',
      variants: 'Варіанти',
      fullName: {
        description: "Ви можете додати прізвище та по-батькові щоб відчути як звучатиме ім'я.",
        example_m: 'Наприклад, Петренко {{name}} Петрович',
        example_f: 'Наприклад, Петренко {{name}} Петрівна',
      },
      celebration: {
        title: 'Іменини',
      },
    },
    tabs: {
      girls: 'Дівчатка',
      boys: 'Хлопчики',
    }
  },
  common: {
    search: 'Пошук',
    save: 'Зберегти',
    ok: 'Зрозуміло',
    goTo: {
      settings: 'Перейти до налаштувань',
      home: 'Додому',
    },
    empty: 'Поки порожньо',
    months: {
      rod: {
        month_1: '{{day}} січня',
        month_01: '$t(common.months.rod.month_1)',
        month_2: '{{day}} лютого',
        month_02: '$t(common.months.rod.month_2)',
        month_3: '{{day}} березня',
        month_03: '$t(common.months.rod.month_3)',
        month_4: '{{day}} квітня',
        month_04: '$t(common.months.rod.month_4)',
        month_5: '{{day}} травня',
        month_05: '$t(common.months.rod.month_5)',
        month_6: '{{day}} червня',
        month_06: '$t(common.months.rod.month_6)',
        month_7: '{{day}} липня',
        month_07: '$t(common.months.rod.month_7)',
        month_8: '{{day}} серпня',
        month_08: '$t(common.months.rod.month_8)',
        month_9: '{{day}} вересня',
        month_09: '$t(common.months.rod.month_9)',
        month_10: '{{day}} жовтня',
        month_11: '{{day}} листопада',
        month_12: '{{day}} грудня',
      },
    },
    download: 'Завантажити',
  },
  error: {
    title: 'Упс. Щось пішло не так',
    text: 'Спробуйте перезавантажити додаток або напишіть нам на пошту:',
  },
  drawer: {
    header: '$t(appName)',
    home: 'Домівка',
    settings: 'Налаштування',
    browse: 'Пошук',
    liked: 'Вподобані',
    random: 'Випадково',
    feedback: 'Відгук',
    review: 'Оцінити',
    privacyPolicy: 'Політика конфіденційності',
    termsAndConditions: 'Умови використання',
    downloadApp: 'Завантажити додаток',
  },
  settings: {
    appearance: 'Вигляд',
    darkMode: {
      title: 'Темний режим',
    },
    female: {
      title: 'Дівчинка',
      lastName: {
        title: 'Прізвище',
        placeholder: 'Введіть прізвище',
      },
      middleName: {
        title: 'По-батькові',
        placeholder: 'Введіть по-батькові',
      },
    },
    male: {
      title: 'Хлопчик',
      lastName: {
        title: '$t(settings.female.lastName.title)',
        placeholder: '$t(settings.female.lastName.placeholder)',
      },
      middleName: {
        title: '$t(settings.female.middleName.title)',
        placeholder: '$t(settings.female.middleName.placeholder)',
      },
    },
  },
  feedback: {
    mail: {
      subject: 'Відгук про додаток "$t(appName)"',
    },
  },
  review: {
    alert: {
      title: 'Упс',
      message: 'Не вийшло :( Спробуйте пізніше',
    },
  },
  notFound: {
    title: 'Упс',
    text: 'А такого у нас немає',
  },
  share: {
    title: 'Поділитися',
  },
  app: {
    banner: {
      text: '$t(appName) у Play Store',
    },
  },
}
