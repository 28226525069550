import i18n, {LanguageDetectorAsyncModule} from 'i18next'
import {initReactI18next} from 'react-i18next'
import {getLocalizationAsync} from 'expo-localization'
import uk from '../translations/uk'

// creating a language detection plugin using expo
// http://i18next.com/docs/ownplugin/#languagedetector
const languageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true, // flags below detection to be async
  detect: async (callback: (lng: string) => void) => {
    const {locale} = await getLocalizationAsync()

    callback(locale)
  },
  init: () => {
  },
  cacheUserLanguage: () => {
  },
}

export const initI18n = () => {
  i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'uk',

      resources: {
        uk: {
          common: uk,
        }
      },

      // have a common namespace used around the full app
      ns: ['common'],
      defaultNS: 'common',

      debug: false,

      // cache: {
      //   enabled: true
      // },

      interpolation: {
        escapeValue: false, // not needed for react as it does escape per default to prevent xss!
      },
      react: {
        bindI18n: 'languageChanged',
        bindI18nStore: false,
        useSuspense: false,
      }
    })
}
