import {RequestMethods} from "./RequestMethods"

class NetworkService {
  async get(url: string, config: RequestInit = {}): Promise<Response> {
    config.method = RequestMethods.Get

    return fetch(url, config)
  }
}

export default new NetworkService()
