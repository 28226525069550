import NetworkService from "../NetworkService"
import {Entity} from "./Entity"
import {EntityParams} from "./EntityParams"
import {EntityError} from "./EntityError"
import {EntityNotFoundError} from "./EntityNotFoundError"
import {EntityServiceInterface} from "./EntityServiceInterface"

export abstract class NetworkEntityService<E extends Entity, P extends EntityParams> implements EntityServiceInterface<E, P> {
  public abstract getList(params: P): Promise<Array<E>>
  public abstract getSingle(id: number): Promise<E>
  protected abstract getBaseUrl(): string

  protected async get(url: string): Promise<any> {
    const headers = new Headers()

    headers.append('Accept', 'application/json')

    const response = await NetworkService.get(this.getBaseUrl() + url, {
      headers,
    })

    if (response.status === 404) {
      throw new EntityNotFoundError()
    }

    if (!response.ok) {
      throw new EntityError()
    }

    return response.json()
  }
}
