class SystemService {
  public isDev(): boolean {
    return __DEV__
  }

  public isProduction(): boolean {
    return !this.isDev()
  }
}

export default new SystemService()
