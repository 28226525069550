import React from "react"
import DrawerItemFeedback from "./DrawerItem/DrawerItemFeedback"
import DrawerItemDownloadApp from "./DrawerItem/DrawerItemDownloadApp"
import {Drawer as KittenDrawer, DrawerItem, DrawerProps} from '@ui-kitten/components'
import {useTranslation} from "react-i18next"
import SearchIcon from "../Icons/SearchIcon"
import BulbIcon from "../Icons/BulbIcon"
import HeartOutlineIcon from "../Icons/HeartOutlineIcon"
import SettingsIcon from "../Icons/SettingsIcon"
import PrivacyPolicyIcon from "../Icons/PrivacyPolicyIcon"
import TermsAndConditionsIcon from "../Icons/TermsAndConditionsIcon"

const Drawer: React.FC<DrawerProps> = props => {
  const {t} = useTranslation()

  return (
    <KittenDrawer {...props}>
      <DrawerItem title={t('drawer.browse') as string} accessoryLeft={SearchIcon}/>
      <DrawerItem title={t('drawer.random') as string} accessoryLeft={BulbIcon}/>
      <DrawerItem title={t('drawer.liked') as string} accessoryLeft={HeartOutlineIcon}/>
      <DrawerItem title={t('drawer.settings') as string} accessoryLeft={SettingsIcon}/>
      <DrawerItem title={t('drawer.privacyPolicy') as string} accessoryLeft={PrivacyPolicyIcon}/>
      <DrawerItem title={t('drawer.termsAndConditions') as string} accessoryLeft={TermsAndConditionsIcon}/>
      <DrawerItemFeedback/>
      <DrawerItemDownloadApp/>
    </KittenDrawer>
  )
}

export default Drawer
