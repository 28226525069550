import React, {useCallback} from 'react'
import {Button, Divider, Layout, Text} from "@ui-kitten/components"
import {Image, StyleSheet, View} from "react-native"
import {useTranslation} from "react-i18next"
import system from "../config/system"
import useFeature from "../hooks/useFeature"
import {Features} from "../config/Features"
import UserAgentHelper from "../helpers/UserAgentHelper"

const AppBanner: React.FC = () => {
  const {t} = useTranslation()
  const [visible, hideFeature] = useFeature(Features.AppBanner)
  const handlePress = useCallback(() => {
    window.open(system.playStoreUrl, '_blank')
    hideFeature()
  }, [])

  if (!UserAgentHelper.isAndroid()) {
    return null
  }

  if (!visible) {
    return null
  }

  return (
    <>
      <Layout style={styles.appBannerWrapper}>
        <Image source={require('../../assets/icon.png')} style={styles.appBannerLogo}/>
        <View style={styles.appBannerTextWrapper}>
          <Text>{t('app.banner.text') as string}</Text>
          <Button
            appearance="outline"
            size="tiny"
            style={styles.appBannerButton}
            onPress={handlePress}
          >
            {t('common.download') as string}
          </Button>
        </View>
        <Button
          appearance="ghost"
          style={styles.appBannerClose}
          onPress={hideFeature}
        >
          X
        </Button>
      </Layout>
      <Divider/>
    </>
  )
}

const styles = StyleSheet.create({
  appBannerWrapper: {
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  appBannerLogo: {
    width: 50,
    height: 50,
    borderRadius: 10,
  },
  appBannerTextWrapper: {
    marginLeft: 15,
    marginRight: 'auto',
  },
  appBannerButton: {
    marginTop: 5,
  },
  appBannerClose: {
  },
})

export default AppBanner
