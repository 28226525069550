import React, {useCallback} from 'react'
import {DrawerItem} from '@ui-kitten/components'
import {useTranslation} from "react-i18next"
import * as Linking from "expo-linking"
import system from "../../../config/system"
import FeedbackIcon from "../../Icons/FeedbackIcon"

const DrawerItemFeedback: React.FC = () => {
  const {t} = useTranslation()
  const handlePress = useCallback(() => {
    Linking.openURL(`mailto:${system.supportEmail}?subject=${t('feedback.mail.subject')}`)
  }, [])

  return (
    <DrawerItem
      title={t('drawer.feedback') as string}
      onPress={handlePress}
      accessoryLeft={FeedbackIcon}
    />
  )
}

export default DrawerItemFeedback
