import {set} from "lodash"
import {AppThunk} from "../types"
import {Feature} from "../../types/settings/feature"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"

interface SettingsState {
  params: {
    darkMode: boolean | null,
    male: {
      lastName?: string | null,
      middleName?: string | null,
    },
    female: {
      lastName?: string | null,
      middleName?: string | null,
    },
  },
  features: {
    fullName: Feature,
    appBanner: Feature,
  }
}
const initialState: SettingsState = {
  params: {
    darkMode: null,
    male: {
      lastName: null,
      middleName: null,
    },
    female: {
      lastName: null,
      middleName: null,
    },
  },
  features: {
    fullName: {
      isShown: false,
    },
    appBanner: {
      isShown: false,
    }
  }
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setParam(state, action: PayloadAction<{param: string, value: any}>) {
      set(state, `params.${action.payload.param}`, action.payload.value)
    },
    showFeature(state, action: PayloadAction<{name: string}>) {
      set(state, `features.${action.payload.name}.isShown`, true)
    },
  },
})

export const {setParam, showFeature} = settingsSlice.actions

export default settingsSlice.reducer

export function setSettingsParam(name: string, value: any): AppThunk {
  return dispatch => {
    dispatch(setParam({param: name, value}))
  }
}

export function showSettingsFeature(name: string): AppThunk {
  return dispatch => {
    dispatch(showFeature({name}))
  }
}
