export default {
  "color-primary-100": "#FFECD2",
  "color-primary-200": "#FFD3A6",
  "color-primary-300": "#FFB579",
  "color-primary-400": "#FF9758",
  "color-primary-500": "#FF6721",
  "color-primary-600": "#DB4818",
  "color-primary-700": "#B72F10",
  "color-primary-800": "#931A0A",
  "color-primary-900": "#7A0C06",
  "color-primary-transparent-100": "rgba(255, 103, 33, 0.08)",
  "color-primary-transparent-200": "rgba(255, 103, 33, 0.16)",
  "color-primary-transparent-300": "rgba(255, 103, 33, 0.24)",
  "color-primary-transparent-400": "rgba(255, 103, 33, 0.32)",
  "color-primary-transparent-500": "rgba(255, 103, 33, 0.4)",
  "color-primary-transparent-600": "rgba(255, 103, 33, 0.48)",
  "color-success-100": "#F1FDD1",
  "color-success-200": "#DFFBA4",
  "color-success-300": "#C5F575",
  "color-success-400": "#ABEC52",
  "color-success-500": "#85E01D",
  "color-success-600": "#68C015",
  "color-success-700": "#4FA10E",
  "color-success-800": "#388109",
  "color-success-900": "#286B05",
  "color-success-transparent-100": "rgba(133, 224, 29, 0.08)",
  "color-success-transparent-200": "rgba(133, 224, 29, 0.16)",
  "color-success-transparent-300": "rgba(133, 224, 29, 0.24)",
  "color-success-transparent-400": "rgba(133, 224, 29, 0.32)",
  "color-success-transparent-500": "rgba(133, 224, 29, 0.4)",
  "color-success-transparent-600": "rgba(133, 224, 29, 0.48)",
  "color-info-100": "#CEFEFE",
  "color-info-200": "#9EF7FE",
  "color-info-300": "#6EE9FE",
  "color-info-400": "#4AD6FD",
  "color-info-500": "#0FB9FC",
  "color-info-600": "#0A90D8",
  "color-info-700": "#076CB5",
  "color-info-800": "#044D92",
  "color-info-900": "#023778",
  "color-info-transparent-100": "rgba(15, 185, 252, 0.08)",
  "color-info-transparent-200": "rgba(15, 185, 252, 0.16)",
  "color-info-transparent-300": "rgba(15, 185, 252, 0.24)",
  "color-info-transparent-400": "rgba(15, 185, 252, 0.32)",
  "color-info-transparent-500": "rgba(15, 185, 252, 0.4)",
  "color-info-transparent-600": "rgba(15, 185, 252, 0.48)",
  "color-warning-100": "#FEF8CE",
  "color-warning-200": "#FEEF9D",
  "color-warning-300": "#FEE36C",
  "color-warning-400": "#FDD748",
  "color-warning-500": "#FCC40C",
  "color-warning-600": "#D8A308",
  "color-warning-700": "#B58406",
  "color-warning-800": "#926603",
  "color-warning-900": "#785102",
  "color-warning-transparent-100": "rgba(252, 196, 12, 0.08)",
  "color-warning-transparent-200": "rgba(252, 196, 12, 0.16)",
  "color-warning-transparent-300": "rgba(252, 196, 12, 0.24)",
  "color-warning-transparent-400": "rgba(252, 196, 12, 0.32)",
  "color-warning-transparent-500": "rgba(252, 196, 12, 0.4)",
  "color-warning-transparent-600": "rgba(252, 196, 12, 0.48)",
  "color-danger-100": "#FFECDC",
  "color-danger-200": "#FFD3BA",
  "color-danger-300": "#FFB598",
  "color-danger-400": "#FF997E",
  "color-danger-500": "#FF6A54",
  "color-danger-600": "#DB453D",
  "color-danger-700": "#B72A2E",
  "color-danger-800": "#931A27",
  "color-danger-900": "#7A1024",
  "color-danger-transparent-100": "rgba(255, 106, 84, 0.08)",
  "color-danger-transparent-200": "rgba(255, 106, 84, 0.16)",
  "color-danger-transparent-300": "rgba(255, 106, 84, 0.24)",
  "color-danger-transparent-400": "rgba(255, 106, 84, 0.32)",
  "color-danger-transparent-500": "rgba(255, 106, 84, 0.4)",
  "color-danger-transparent-600": "rgba(255, 106, 84, 0.48)",

  // new definitions
  "color-gender-m-100": "#F2F6FF",
  "color-gender-m-200": "#D9E4FF",
  "color-gender-m-300": "#A6C1FF",
  "color-gender-m-400": "#598BFF",
  "color-gender-m-500": "#3366FF",
  "color-gender-m-600": "#274BDB",
  "color-gender-m-700": "#1A34B8",
  "color-gender-m-800": "#102694",
  "color-gender-m-900": "#091C7A",

  "color-gender-f-100": "#FFF2F2",
  "color-gender-f-200": "#FFD6D9",
  "color-gender-f-300": "#FFA8B4",
  "color-gender-f-400": "#FF708D",
  "color-gender-f-500": "#FF3D71",
  "color-gender-f-600": "#DB2C66",
  "color-gender-f-700": "#B81D5B",
  "color-gender-f-800": "#94124E",
  "color-gender-f-900": "#700940",
}
