import SystemService from "../SystemService"
import {ConsoleLogger} from "./ConsoleLogger"
import {NullLogger} from "./NullLogger"
import {LoggerInterface} from "./LoggerInterface"

class LoggerService {
  private instance: LoggerInterface|null = null

  factory() {
    if (this.instance === null) {
      this.instance = SystemService.isDev() ? new ConsoleLogger() : new NullLogger()
    }

    return this.instance
  }
}

export default (new LoggerService()).factory()
