import {capitalize, startCase, truncate, toLower, includes} from "lodash"

class StringHelper {
  capitalize(value: string): string {
    return capitalize(value)
  }

  titlelize(string: string): string {
    return startCase(string)
  }

  toLower(string: string): string {
    return toLower(string)
  }

  truncate(string: any = '', length: number = 15, omission : string = '...'): string {
    if (typeof string !== 'string') {
      return ''  
    }
    
    if (string.length <= length){
      return string
    }

    return truncate(string, {
      length,
      omission,
    })
  }

  includes(string1: string, string2: string): boolean {
    return includes(this.toLower(string1), this.toLower(string2))
  }
}

export default new StringHelper()
