import React, {useCallback} from 'react'
import {Text, StyleSheet, View} from "react-native"
import ScreenWrapper from "../components/ScreenWrapper"
import {useTranslation} from "react-i18next"
import * as Linking from "expo-linking"
import system from "../config/system"

const ErrorScreen: React.FC = () => {
  const {t} = useTranslation()
  const handlePress = useCallback(() => {
    Linking.openURL(`mailto:${system.supportEmail}`)
  }, [])

  return (
    <ScreenWrapper>
      <View style={styles.wrapper}>
        <Text style={styles.title}>{t('error.title')}</Text>
        <Text style={styles.text}>{t('error.text')}</Text>
        <Text style={styles.email} onPress={handlePress}>{system.supportEmail}</Text>
      </View>
    </ScreenWrapper>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10%',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
    marginVertical: 10,
  },
  email: {
    color: '#007bff',
  },
})

export default ErrorScreen
