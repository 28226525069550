import ErrorTrackingService from "./src/services/ErrorTrackingService"

ErrorTrackingService.init()

import React, {Suspense} from 'react'
import {persistor, store} from './src/redux/configureStore'
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import {initI18n} from './src/services/i18n'
import AppContainer from "./src/containers/AppContainer"
import BareLoadingScreen from "./src/screens/BareLoadingScreen"
import {useTranslation} from "react-i18next"
import AppLoading from "expo-app-loading"
import ErrorScreen from "./src/screens/ErrorScreen"

initI18n()

const App: React.FC = () => {
  const {ready} = useTranslation()

  if (!ready) {
    return (
      <AppLoading onError={ErrorTrackingService.track}/>
    )
  }

  const ErrorBoundary = ErrorTrackingService.getErrorBoundary()

  return (
    <Suspense fallback={<BareLoadingScreen/>}>
      <ErrorBoundary FallbackComponent={ErrorScreen}>
        <PersistGate loading={null} persistor={persistor}>
          <Provider store={store}>
            <AppContainer/>
          </Provider>
        </PersistGate>
      </ErrorBoundary>
    </Suspense>
  )
}

export default App
