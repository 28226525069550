import React, {useCallback, useMemo} from 'react'
import {IndexPath} from '@ui-kitten/components'
import ScreenWrapper from "../ScreenWrapper"
import DrawerHeader from "./DrawerHeader"
import {DrawerContentComponentProps} from "@react-navigation/drawer/src/types"
import {ViewProps} from "react-native"
import {RenderProp} from "@ui-kitten/components/devsupport"
import Drawer from "./Drawer"

const DrawerContent: React.FC<DrawerContentComponentProps> = ({navigation, state}) => {
  const handleSelect = useCallback<(index: IndexPath) => void>(index => {
    // a hack to avoid error when clicking a link from the menu that does not trigger a route
    // I don't like this
    if (index.row > 5) {
      return
    }

    return navigation.navigate(state.routeNames[index.row])
  }, [navigation.navigate, state.routeNames])
  const selectedIndex = useMemo<IndexPath>(() => new IndexPath(state.index), [state.index])
  const drawerHeader = useCallback<RenderProp<ViewProps>>(props => <DrawerHeader {...props}/>, [])

  return (
    <ScreenWrapper>
      <Drawer
        selectedIndex={selectedIndex}
        onSelect={handleSelect}
        header={drawerHeader}
      />
    </ScreenWrapper>
  )
}

export default DrawerContent
