import React from 'react'
import {useEffect, useState} from 'react'

const useLazyScreens = (names: string[], TargetComponent: React.FC<{components: React.FC[]}>) => {
  const [components, setComponents] = useState([])
  const promises = names.map(name => import(`../screens/${name}`))

  useEffect(() => {
    Promise
      .all(promises)
      .then(result => {
        // @ts-ignore
        setComponents(result.map(item => item.default))
      })
  }, [])

  if (!components.length) {
    return null
  }

  return <TargetComponent components={components}/>
}

export default useLazyScreens
