import {Name} from "../../../services/Entity/Name/Name"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {Props} from "avataaars"

interface ImageState {
  images: {
    [key: string]: Props,
  }
}
const initialState: ImageState = {
  images: {}
}

const shareSlice = createSlice({
  name: 'names/image',
  initialState,
  reducers: {
    save(state, action: PayloadAction<{name: Name, props: Props}>) {
      state.images[action.payload.name.id] = action.payload.props
    }
  }
})

export const {save} = shareSlice.actions

export default shareSlice.reducer
