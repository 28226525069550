import {LoggerInterface} from "./LoggerInterface"

export class ConsoleLogger implements LoggerInterface {
  public info(message?: any, ...optionalParams: any[]): void {
    console.info(message, ...optionalParams)
  }

  public error(error: Error): void {
    console.error(error)
  }
}
