import React, {useCallback} from 'react'
import {createDrawerNavigator} from '@react-navigation/drawer'
import routes from "../config/routes"
import DrawerContent from "../components/Navigation/DrawerContent"
import {useWindowDimensions} from "react-native"
import {useTranslation} from "react-i18next"

const {Navigator, Screen} = createDrawerNavigator()

export type MainDrawerRouteParamList = {
  NameDetails: {
    id: number,
  }
}

export const MainDrawerNavigatorContent: React.FC<{components: React.FC[]}> = ({components}) => {
  const {t} = useTranslation()
  const [
    BrowseNamesScreen,
    RandomNameScreen,
    LikedScreen,
    PrivacyPolicyScreen,
    TermsAndConditionsScreen,
    NotFoundScreen,
    SettingsScreen,
  ] = components
  const drawerContent = useCallback(props => <DrawerContent {...props} />, [])
  const dimensions = useWindowDimensions()
  const drawerType = dimensions.width >= 992 ? 'permanent' : 'back'

  return (
    <Navigator drawerContent={drawerContent} drawerType={drawerType}>
      <Screen
        name={routes.main.browse}
        component={BrowseNamesScreen}
      />
      <Screen
        name={routes.main.random}
        component={RandomNameScreen}
        options={{ title: t('screens.random.title') }}
      />
      <Screen
        name={routes.main.liked}
        component={LikedScreen}
      />
      <Screen
        name={routes.settings.root}
        component={SettingsScreen}
        options={{
          title: t('screens.settings.title'),
        }}
      />
      <Screen
        name={routes.privacyPolicy}
        component={PrivacyPolicyScreen}
        options={{ title: t('screens.privacyPolicy.title') }}
      />
      <Screen
        name={routes.termsAndConditions}
        component={TermsAndConditionsScreen}
        options={{ title: t('screens.termsAndConditions.title') }}
      />
      <Screen
        name={routes.notFound}
        component={NotFoundScreen}
        options={{ title: t('notFound.title') }}
      />
    </Navigator>
  )
}
