import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import Constants from "expo-constants"

export const init = () => {
  Bugsnag.start({
    apiKey: Constants.manifest?.extra?.bugsnag?.apiKey,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production'],
  })
}

export default Bugsnag
