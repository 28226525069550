import {useMemo} from 'react'
import {StatusBarStyle} from "react-native"
import useAppTheme from "./useAppTheme"

const useThemeStatusBarStyle: () => StatusBarStyle = () => {
  const [darkMode] = useAppTheme()

  return useMemo(() => {
    return darkMode ? 'light-content' : 'dark-content'
  }, [darkMode])
}

export default useThemeStatusBarStyle
