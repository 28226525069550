import system from "../../config/system"
import {NetworkEntityService} from "./NetworkEntityService"
import {Entity} from "./Entity"
import {EntityParams} from "./EntityParams"

export abstract class SheetEntityService<E extends Entity, P extends EntityParams> extends NetworkEntityService<E, P> {
  protected abstract resourceName: string

  protected getBaseUrl(): string {
    return system.siteUrl
  }

  public async getList(): Promise<E[]> {
    return this.get(`/${this.resourceName}/all.json`)
  }

  public async getSingle(id: number): Promise<E> {
    return this.get(`/${this.resourceName}/${id}.json`)
  }
}
