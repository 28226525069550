import {combineReducers} from 'redux'
import names from "./names"
import settings from './settings'
import {persistReducer} from "redux-persist"
import categories from "./categories"
import {storage} from "../storage"

const settingsReducer = persistReducer(
  {
    key: 'settings',
    storage: storage,
    whitelist: ['params', 'features'],
  },
  settings
)

export default combineReducers({
  names,
  settings: settingsReducer,
  categories,
})
