import React from 'react'
import {ApplicationProvider, IconRegistry, Layout} from "@ui-kitten/components"
import {EvaIconsPack} from "@ui-kitten/eva-icons"
import {mapping} from "@eva-design/eva"
import AppNavigator from "../navigation/AppNavigator"
import {StatusBar, StyleSheet} from "react-native"
import useThemeStatusBarStyle from "../hooks/useThemeStatusBarStyle"
import useAppTheme from "../hooks/useAppTheme"
import AppBanner from "../components/AppBanner"

const AppContainer: React.FC = () => {
  const [, theme] = useAppTheme()
  const statusBarStyle = useThemeStatusBarStyle()

  return (
    <>
      <StatusBar barStyle={statusBarStyle}/>
      <IconRegistry icons={EvaIconsPack}/>
      <ApplicationProvider mapping={mapping} theme={theme}>
        <Layout style={styles.wrapper} level="4">
          <Layout style={styles.content} level="3">
            <AppBanner/>
            <AppNavigator/>
          </Layout>
        </Layout>
      </ApplicationProvider>
    </>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    flex: 1,
  },
  content: {
    maxWidth: 1199,
    flexGrow: 1,
    width: '100%',
    overflow: 'hidden',
  },
})

export default AppContainer
