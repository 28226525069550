// TODO: rename
export default {
  name: {
    view: 'name_view',
    like: 'name_like',
    unlike: 'name_unlike',
    shared: 'name_shared',
    shareDismissed: 'name_share_dismissed',
  },
  random: {
    swipe: {
      left: 'random_swipe_left',
      right: 'random_swipe_right',
    },
  },
  search: 'search',
  filter: 'filter',
  settings: {
    param:{
      changed: 'settings_param_changed',
    },
  },
}
