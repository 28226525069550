import {logEvent, setAnalyticsCollectionEnabled, setCurrentScreen, setDebugModeEnabled} from "expo-firebase-analytics"
import events from "../config/events"
import ErrorTrackingService from "./ErrorTrackingService"
import SystemService from "./SystemService"
import LoggerService from "./Logger/LoggerService"

class AnalyticsService {
  private readonly isDev: boolean = false

  constructor() {
    this.isDev = !SystemService.isProduction()

    if (this.isDev) {
        setAnalyticsCollectionEnabled(false).catch(LoggerService.error)
        setDebugModeEnabled(true).catch(LoggerService.error)
    } else {
      setAnalyticsCollectionEnabled(true).catch(ErrorTrackingService.track)
    }
  }

  public async logNameView(): Promise<void> {
    await this.logEvent(events.name.view)
  }

  public async logNameLike(): Promise<void> {
    await this.logEvent(events.name.like)
  }

  public async logNameUnlike(): Promise<void> {
    await this.logEvent(events.name.unlike)
  }

  public async logRandomSwipeRight(): Promise<void> {
    await this.logEvent(events.random.swipe.right)
  }

  public async logRandomSwipeLeft(): Promise<void> {
    await this.logEvent(events.random.swipe.left)
  }

  public async logSearch(): Promise<void> {
    await this.logEvent(events.search)
  }

  public async logFilter(): Promise<void> {
    await this.logEvent(events.filter)
  }

  public async logSettingsParamChange(): Promise<void> {
    await this.logEvent(events.settings.param.changed)
  }

  public async logNameShare(): Promise<void> {
    await this.logEvent(events.name.shared)
  }

  public async logNameShareDismiss(): Promise<void> {
    await this.logEvent(events.name.shareDismissed)
  }

  public async logCurrentScreen(screenName?: string, screenClassOverride?: string): Promise<void> {
    try {
      if (this.isDev) {
        LoggerService.info(`Current Screen ${screenName}`, screenClassOverride)
      } else {
        await this.logEvent('screen_view', { screen_name: screenName })
      }
    } catch (e) {
      ErrorTrackingService.track(e)
    }
  }

  private async logEvent(name: string, properties?: { [key: string]: any }): Promise<void> {
    try {
      if (this.isDev) {
        LoggerService.info(`Event: ${name}`, properties)
      } else {
        await logEvent(name, properties)
      }

    } catch (e) {
      ErrorTrackingService.track(e)
    }
  }
}

export default new AnalyticsService()
