import React from 'react'
import ErrorScreen from "../../screens/ErrorScreen"
import LoggerService from "../../services/Logger/LoggerService"
import {OnErrorCallback} from "@bugsnag/core"

export type ErrorBoundaryProps = {
  onError?: OnErrorCallback
  FallbackComponent?: React.ComponentType<{
    error: Error
    info: React.ErrorInfo
    clearError: () => void
  }>
}

type ErrorBoundaryState = {
  hasError: boolean,
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: Readonly<ErrorBoundaryProps>) {
    super(props)
    this.state = {
      hasError: false
    }
  }
  
  static defaultProps: ErrorBoundaryProps = {
    FallbackComponent: ErrorScreen,
  }

  static getDerivedStateFromError() {
    return {
      hasError: true
    }
  }

  componentDidCatch(error: Error) {
    // logger instead of ErrorTracker to prevent loops
    // this component is supposed to be used in dev mode only
    LoggerService.error(error)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorScreen/>
    }

    return this.props.children
  }
}

export default ErrorBoundary
