import SheetNameService from "../../../services/Entity/Name/SheetNameService"
import {AppThunk} from "../../types"
import {Name} from "../../../services/Entity/Name/Name"
import {NameParams} from "../../../services/Entity/Name/NameParams"
import {createSlice, PayloadAction, createSelector} from "@reduxjs/toolkit"
import {sortBy, deburr, sampleSize} from "lodash"
import {RootState} from "../../rootReducer"
import StringHelper from "../../../helpers/StringHelper"
import LoggerService from "../../../services/Logger/LoggerService"
import {Category} from "../../../services/Entity/Category/Category"

export interface ListState {
  data: Name[],
  params: NameParams,
  error: boolean,
  success: boolean,
  loading: boolean,
  hasMore: boolean,
  page: number,
  random: Name[],
}

const initialState: ListState = {
  params: {},
  data: [],
  error: false,
  success: false,
  loading: false,
  hasMore: true,
  page: 1,
  random: [],
}

const listSlice = createSlice({
  name: 'names/list',
  initialState: initialState,
  reducers: {
    load(state) {
      state.error = false
      state.success = false
      state.loading = true
    },
    success(state, action: PayloadAction<Name[]>) {
      state.data = sortBy(action.payload, name => deburr(name.name))
      state.error = false
      state.success = true
      state.loading = false
      state.hasMore = false
      state.page = 1
    },
    error(state) {
      state.error = true
      state.success = false
      state.loading = false
    },
    setParams(state, action: PayloadAction<NameParams>) {
      state.params = action.payload
    },
    random(state) {
      state.random = sampleSize<Name>(state.data, 10)
    },
  }
})

export const {load, success, error, setParams, random} = listSlice.actions

export default listSlice.reducer

export function loadNames(): AppThunk {
  return dispatch => {
    dispatch(load())

    SheetNameService
      .getList()
      .then(data => dispatch(success(data)))
      .catch(e => {
        LoggerService.error(e)
        dispatch(error())
      })
  }
}

export function setSearchParams(params: NameParams): AppThunk {
  return dispatch => {
    if (typeof params.name !== "string" || params.name.length === 0) {
      delete(params.name)
    } else {
      params.name = StringHelper.toLower(params.name)
    }

    dispatch(setParams(params))
  }
}

export function getRandomNames(): AppThunk {
  return dispatch => {
    dispatch(random())
  }
}

export const selectNames = createSelector<RootState, NameParams, Name[], Category[], Name[]>(
  [
    state => state.modules.names.list.params,
    state => state.modules.names.list.data,
    state => state.modules.categories.data,
  ],

  (params, names, categories) => {
    let category: Category|undefined

    if (typeof params.category === "number" && params.category) {
      category = categories.find(cat => cat.id === params.category)
    }

    return names.filter(name => {
        if (typeof params.gender === 'string' && name.gender !== params.gender) {
          return false
        }

        if (typeof params.name === 'string' && params.name !== '' && !StringHelper.includes(name.name, params.name)) {
          return false
        }

        if (category && !category.names.includes(name.id)) {
          return false
        }

        return true
      })
  }
)
