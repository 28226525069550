import React from 'react'
import {SafeAreaView, StyleSheet} from "react-native"

const ScreenWrapper: React.FC = ({children}) => (
  <SafeAreaView style={styles.wrapper}>
    {children}
  </SafeAreaView>
)

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
})

export default ScreenWrapper
